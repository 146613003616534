<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb
      class="my-2"
      title="Aceptación Múltiple"
      :icon="'mdi-email-outline'"
    ></BaseBreadcrumb>
    <v-btn
      class="mb-2 hidden-md-and-up"
      color="primary"
      :disabled="selected.length === 0 || fetching"
      :loading="accepting"
      @click="acceptSelectedItems"
    >
      {{ selectedItemsFormatted }}
    </v-btn>
    <v-data-table
      :no-data-text="noDataFormatted"
      :dense="dense"
      :loading="fetching"
      loading-text="Cargando... Espera, por favor"
      v-model="selected"
      :headers="headers"
      :items="items"
      item-key="id"
      :show-select="fetched && items.length > 0"
      class="elevation-1 mb-4 py-4"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            <v-text-field
              :disabled="fetching"
              class="mt-4"
              v-model="reference"
              label="Referencia"
              outlined
              dense
            ></v-text-field>
          </v-toolbar-title>
          <v-btn
            class="mb-2 mx-2"
            color="primary"
            :loading="fetching"
            :disabled="!reference || accepting"
            @click="fetchItems"
            >Buscar</v-btn
          >
          <v-btn
            class="mb-2 mx-2 hidden-sm-and-down"
            color="primary"
            :disabled="fetching || items.length === 0"
            @click="dense = !dense"
            ><v-icon> {{ denseIcon }} </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="mb-2 hidden-sm-and-down"
            color="primary"
            :disabled="selected.length === 0 || fetching"
            :loading="accepting"
            @click="acceptSelectedItems"
          >
            {{ selectedItemsFormatted }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon @click="processItem(item)"> mdi-draw </v-icon>
      </template>
    </v-data-table>

    <v-dialog scrollable width="600" v-model="acceptedDialog">
      <v-card class="pa-1">
        <v-card-title class="text-h5">
          {{ acceptedResponseTitle }}
        </v-card-title>
        <v-card-subtitle> {{ acceptedResponseSubtitle }} </v-card-subtitle>
        <v-card-text>
          <div class="mt-4">
            <li
              class="red--text text--darken-2"
              v-for="(errorItem, index) in errorItems"
              :key="index"
            >
              ID: {{ errorItem.documentSetId }}, Motivo:
              {{ errorItem.errorMessage }}
            </li>
          </div>
        </v-card-text>
        <v-card-actions class="pb-2">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="acceptedDialog = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { GoToUrl } from "../../../utils/utils";

export default {
  name: "RemoteSignaturesMultiAccept",

  data: () => ({
    acceptedResponseTitle: "",
    acceptedResponseSubtitle: "",
    acceptedDialog: false,
    errorItems: [],
    dense: false,
    fetching: false,
    fetched: false,
    accepting: false,
    lastFetchedReference: "",
    reference: "",
    selected: [],
    headers: [
      {
        text: "NOMBRE",
        sortable: false,
        value: "documentSetName"
      },
      { text: "ACCIONES", value: "actions", sortable: false }
    ]
  }),
  methods: {
    fetchItems() {
      this.selected = [];
      this.fetching = true;
      this.$store
        .dispatch("getMyDocuments", {
          itemFilteringDocumentStatus: [200],
          recipientAuthenticationType: 0,
          recipientActionType: [20, 21],
          itemFilteringDates: [],
          reference: this.reference,
          itemFilteringRecipientStatus: [2, 3],
          limit: 1000,
          offset: 0
        })
        .then(() => {
          this.lastFetchedReference = JSON.parse(
            JSON.stringify(this.reference)
          );
          this.fetched = true;
          this.fetching = false;
        })
        .catch(err => {
          console.log(err);
          this.fetching = false;
        });
    },

    acceptSelectedItems() {
      if (this.selected.length > 0) {
        this.accepting = true;
        var parsedSelectedItems = JSON.parse(JSON.stringify(this.selected));
        var body = parsedSelectedItems.map(item => item.id);

        this.$store
          .dispatch("acceptMultipleMyDocuments", body)
          .then(response => {
            this.accepting = false;
            if (response.status === 200) {
              var itemsProcessed = JSON.parse(
                JSON.stringify(this.selected.length)
              );
              this.selected = [];
              this.$store.dispatch("clearMyDocuments");
              this.lastFetchedReference = "";
              if (response.data && response.data.length > 0) {
                this.acceptedResponseTitle = "Petición procesada con errores";
                this.acceptedResponseSubtitle =
                  itemsProcessed -
                  response.data.length +
                  " sobre(s) OK, " +
                  response.data.length +
                  " sobre(s) con error";
                this.errorItems = response.data;
                this.acceptedDialog = true;
              } else {
                this.acceptedResponseTitle =
                  "Petición procesada correctamente sin errores";
                this.acceptedResponseSubtitle = itemsProcessed + " sobre(s) OK";
                this.errorItems = [];
                this.acceptedDialog = true;
              }
            } else {
              this.$toasted.global.toast_error({
                message: "No se ha podido procesar la petición"
              });
            }
          })
          .catch(err => {
            console.log(err);
            this.$toasted.global.toast_error({
              message: "No se ha podido procesar la petición"
            });
            this.accepting = false;
          });
      }
    },

    processItem(item) {
      GoToUrl(item.link);
    }
  },

  computed: {
    denseIcon() {
      if (this.dense) {
        return "mdi-arrow-expand-vertical";
      } else {
        return "mdi-arrow-collapse-vertical";
      }
    },

    selectedItemsFormatted() {
      return "Aceptar " + this.selected.length + " sobre(s)";
    },
    noDataFormatted() {
      if (this.lastFetchedReference) {
        return (
          "No hay sobres disponibles para aceptación múltiple con la referencia " +
          this.lastFetchedReference
        );
      }
      return "Introduce una referencia y pulsa BUSCAR";
    },

    items() {
      if (this.fetched) {
        var documents = JSON.parse(
          JSON.stringify(this.$store.state.myDocuments)
        );
        return documents.filter(document => document.link);
      }
      return [];
    }
  }
};
</script>
